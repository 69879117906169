@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 300"),
    url("assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 400"),
    url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 500"),
    url("assets/fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 600"),
    url("assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 700"),
    url("assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  background-color: #F8F8FB;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

form {
  display: flex;
  flex-direction: column;
}

button:hover {
  .svg-icon {
    color: var(--hover-color) !important;
  }
}

.fw-600 {
  font-weight: 600;
}

ul {
  padding-left: 18px;
}
